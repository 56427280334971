<template>
  <div class="mds-modal-body" :id="formId">
    <mds-layout-grid>
      <mds-row>
        <mds-col :cols="12">
          <div class="mcia-form-title">{{ formData.title }}</div>
        </mds-col>
      </mds-row>
      <mds-row>
        <mds-col :cols="12" :cols-at-m="6">
          <mds-fieldset class="mcia-form-input-field">
            <mds-input v-bind="formData.firstName" v-model="formData.firstName.value" />
          </mds-fieldset>
        </mds-col>
        <mds-col :cols="12" :cols-at-m="6">
          <mds-fieldset class="mcia-form-input-field">
            <mds-input v-bind="formData.lastName" v-model="formData.lastName.value" />
          </mds-fieldset>
        </mds-col>
      </mds-row>
      <mds-row>
        <mds-col :cols="12" :cols-at-m="6">
          <mds-fieldset class="mcia-form-input-field">
            <mds-input v-bind="formData.userName" v-model="formData.userName.value" />
          </mds-fieldset>
        </mds-col>
        <mds-col :cols="12" :cols-at-m="6">
          <mds-fieldset class="mcia-form-input-field">
            <mds-input
              v-bind="formData.phone"
              v-model="formData.phone.value"
              required
            />
          </mds-fieldset>
        </mds-col>
      </mds-row>
      <mds-row>
        <mds-col :cols="12" :cols-at-m="6">
          <mds-fieldset class="mcia-form-input-field">
            <mds-combo-box
              v-bind="formData.country"
              v-model="selectedCountry"
              required
              :data-set="countries"
            />
          </mds-fieldset>
        </mds-col>
        <mds-col :cols="12" :cols-at-m="6">
          <mds-fieldset class="mcia-form-input-field">
            <mds-input v-bind="formData.company" v-model="formData.company.value" />
          </mds-fieldset>
        </mds-col>
      </mds-row>
      <mds-row>
        <mds-col :cols="12">
          <mds-fieldset class="mcia-form-input-field">
            <mds-combo-box
              v-bind="formData.organization"
              v-model="selectedOrganization"
              required
              :data-set="organizations"
            />
          </mds-fieldset>
        </mds-col>
      </mds-row>
      <mds-row>
        <mds-col :cols="12">
          <mds-fieldset
            v-bind="formData.products"
            required
            horizontal
            class="mcia-form-input-checkbox"
            variation="checkbox-group"
          >
            <mds-checkbox
              v-for="product in options"
              v-model="selectedProducts"
              class="mcia-form-input-checkbox-btn"
              :key="`mcia-form-input-checkox-${product.value}`"
              :value="product.value"
            >
              {{ product.label }}
            </mds-checkbox>
          </mds-fieldset>
        </mds-col>
      </mds-row>
      <mds-row>
        <mds-col :cols="12">
          <mds-fieldset
            v-bind="formData.emailUpdate"
            required
            horizontal
            class="mcia-form-input-radio"
            variation="radio-group"
          >
            <mds-radio-button
              v-for="item in formData.emailUpdate.options"
              v-model="selectedEmailUpdate"
              class="mcia-form-input-radio-btn"
              :key="`mcia-form-input-radio-${item.value}`"
              :value="item.value"
            >
              {{ item.label }}
            </mds-radio-button>
          </mds-fieldset>
        </mds-col>
      </mds-row>
      <mds-row>
        <mds-col :cols="12">
          <mds-fieldset class="mcia-form-input-field">
            <mds-input v-bind="formData.otherInfo" v-model="formData.otherInfo.value" />
          </mds-fieldset>
        </mds-col>
      </mds-row>
    </mds-layout-grid>
    <mds-button-container right-aligned class="mcia-form-action-btn">
      <mds-button
        class="mcia-sign-in-menu-cancel-btn"
        variation="secondary"
        @click="handleExitForm"
      >
        Cancel
      </mds-button>
      <mds-button variation="primary" @click="onSubmit">Submit</mds-button>
    </mds-button-container>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import { MdsButton, MdsButtonContainer } from '@mds/button';
import MdsRadioButton from '@mds/radio-button';
import MdsInput from '@mds/input';
import MdsComboBox from '@mds/combo-box';
import MdsFieldset from '@mds/fieldset';
import MdsCheckbox from '@mds/checkbox';
import usersData from '@/data/data.json';
import getStartedFormData from '@/data/getStartedForm.json';
import cloneDeep from 'lodash/cloneDeep';
import trackEvent, { isExternalUser } from '@/utils/googleAnalytics';
import { events } from '@/config/events';
import { getStartedEloquaRegister, postGetStartedToDealx } from '@/services/formService.js';
import getStartedEloquaData from '@/data/getStartedEloquaData.json';

const BANKS_TOOL_GS_FORMID = "banks-tool-sign-in-form";
const FUND_TOOL_GS_FORMID = "fundamentals-tool-sign-in-form";
const GET_STARTED_FORMID = "get-started-form-model";
const GET_STARTED_PAGE_FORMID = "get-started-page";

export default {
  name: 'GetStartedForm',
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    MdsRadioButton,
    MdsInput,
    MdsComboBox,
    MdsCheckbox,
    MdsButton,
    MdsButtonContainer,
    MdsFieldset,
  },
  props: {
    formId: {
      type: String,
      default: 'get-started-form-model',
      required: true,
    }
  },
  data() {
    return {
      formData: cloneDeep(getStartedFormData),
      countries: usersData.countries,
      organizations: usersData.organizations,
      roles: usersData.roles,
      selectedCountry: [],
      selectedOrganization: [],
      selectedRole: [],
      selectedProducts: [],
      selectedEmailUpdate: 'Yes',
      toggle: this.toggle,
      formLocation: '',
      selectedDealXProducts: [],
    };
  },
  mounted() {
    this.selectedProducts = this.selectedDefaultProducts;
  },
  computed: {
    ...mapState({
      selectedDefaultProducts: (state) => state.signIn.selectedProducts,
    }),
    options() {
      var result = [];
      this.formData.products.options.forEach((option) => {
        result.push(option);
      });
      return result;
    },
  },
  methods: {
    validateForm() {
      var isValid = true;
      var reg =
        /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
      if (this.$el.querySelector('#emailAddress').value === '') {
        this.formData.userName.errorText = ['Please type your email address'];
        this.formData.userName.error = true;
        isValid = false;
      } else if (!reg.test(this.$el.querySelector('#emailAddress').value)) {
        this.formData.userName.errorText = ['A valid email address is required'];
        this.formData.userName.error = true;
        isValid = false;
      } else {
        this.formData.userName.error = false;
      }
      if (this.$el.querySelector('#firstName').value === '') {
        this.formData.firstName.error = true;
        isValid = false;
      } else {
        this.formData.firstName.error = false;
      }
      if (this.$el.querySelector('#lastName').value === '') {
        this.formData.lastName.error = true;
        isValid = false;
      } else {
        this.formData.lastName.error = false;
      }
      if (this.$el.querySelector('#phone').value === '') {
        this.formData.phone.error = true;
        isValid = false;
      } else {
        this.formData.phone.error = false;
      }
      if (this.selectedCountry.length === 0) {
        this.formData.country.error = true;
        isValid = false;
      } else {
        this.formData.country.error = false;
      }
      if (this.selectedOrganization.length === 0) {
        this.formData.organization.error = true;
        isValid = false;
      } else {
        this.formData.organization.error = false;
      }
      if (this.$el.querySelector('#company').value === '') {
        this.formData.company.error = true;
        isValid = false;
      } else {
        this.formData.company.error = false;
      }
      if (this.selectedProducts.length === 0) {
        this.formData.products.error = true;
        isValid = false;
      } else {
        this.formData.products.error = false;
      }
      if (this.selectedEmailUpdate.length === 0) {
        this.formData.emailUpdate.error = true;
        isValid = false;
      } else {
        this.formData.emailUpdate.error = false;
      }
      return isValid;
    },
    onSubmit(event) {
      if (this.validateForm()) {
        if (isExternalUser(this.formData.userName.value)){
          this.trackSubmitClick();
        }
        var postData = {};
        postData[this.formData.userName.label] = this.formData.userName.value;
        postData[this.formData.firstName.label] = this.formData.firstName.value;
        postData[this.formData.lastName.label] = this.formData.lastName.value;
        postData[this.formData.country.label] = this.selectedCountry;
        postData[this.formData.organization.label] = this.selectedOrganization;
        postData[this.formData.company.label] = this.formData.company.value;
        postData[this.formData.phone.label] = this.formData.phone.value;
        postData[this.formData.otherInfo.label] = this.formData.otherInfo.value;
        postData[this.formData.products.legend] = this.selectedProducts;
        postData[this.formData.emailUpdate.legend] = this.selectedEmailUpdate;
        postData['Subject'] = '[Morningstar Credit] Get Started - New User Interest';

        this.selectedProducts.forEach((product) => {
          if (getStartedFormData.dealXProducts.includes(product)) {
            this.selectedDealXProducts.push(product);
          }
        })

        if (this.selectedDealXProducts.length == 0) {
          this.sendEmail("internalmail", postData);
        } else if (this.selectedProducts.length == this.selectedDealXProducts.length) {
		      this.sendEmail("externalmail", postData);
		    } else if (this.selectedProducts.length > this.selectedDealXProducts.length) {
          this.sendEmail("internalmail", postData);
          postData[this.formData.products.legend] = this.selectedDealXProducts;
          this.sendEmail("externalmail", postData);
        }
        const postEloquaData = getStartedEloquaData;
        postEloquaData["fieldValues"]?.forEach((item) => {
          switch (item.name) {
            case "firstName":
              item.value = this.formData.firstName?.value?.substring(0, 35);
              break;
            case "lastName":
              item.value = this.formData.lastName?.value?.substring(0, 35);
              break;
            case "emailAddress":
              item.value = this.formData.userName?.value;
              break;
            case "businessPhone":
              item.value = this.formData.phone?.value?.substring(0, 20);
              break;
            case "country":
              item.value = this.selectedCountry?.toString().substring(0, 200);
              break;
            case "company":
              item.value = this.formData.company?.value?.substring(0, 100);
              break;
            case "organization":
              item.value = this.selectedOrganization?.toString().substring(0, 200);
              break;
            case "interestedProducts":
              item.value = this.selectedProducts?.toString().substring(0, 200);
              break;
          }
        });
        getStartedEloquaRegister(postEloquaData);

        var dealxPostData = {};
        dealxPostData["FirstName"] = this.formData.firstName.value;
        dealxPostData["LastName"] = this.formData.lastName.value;
        dealxPostData["Phone"] = this.formData.phone.value;
        dealxPostData["Email"] = this.formData.userName.value;
        dealxPostData["Country"] = this.selectedCountry?.toString();
        dealxPostData["Company"] = this.formData.company.value;
        dealxPostData["Industry"] = this.selectedOrganization?.toString();
        dealxPostData["Description"] = this.selectedProducts?.toString();
        dealxPostData["LeadSource"] = "Website";
        dealxPostData["Lead_Product_Example__c"] = "CMBS Data Feed;CRE Loan Explorer";
        dealxPostData["Research_Opt_In__c"] = this.selectedEmailUpdate.toLowerCase() == "yes" ? true : false;

        postGetStartedToDealx(dealxPostData);

        this.$emit('exit', true);
        this.$emit('toggleNotification', true);
        this.resetForm();
        this.$router.push("/getstarted-thankyou");
      }
      event.preventDefault();
      return false;
    },
    handleExitForm() {
      this.resetForm();
      this.$emit('exit', true);
    },
    resetForm() {
      this.formData = cloneDeep(getStartedFormData);
      this.selectedCountry = [];
      this.selectedOrganization = [];
      this.selectedRole = [];
      this.selectedProducts = ['cre_analytics'];
      this.selectedEmailUpdate = 'Yes';
      this.selectedDealXProducts = [];
    },
    trackSubmitClick(){
      if (this.formId === BANKS_TOOL_GS_FORMID) {
        this.formLocation = "banks page inline";
      } else if (this.formId === FUND_TOOL_GS_FORMID) {
        this.formLocation = "fundamentals page inline";
      } else if (this.formId === GET_STARTED_FORMID) {
        this.formLocation = "get started header";
      } else if (this.formId === GET_STARTED_PAGE_FORMID) {
        this.formLocation = "get started page";
      }
      
      trackEvent(
        events.event.GET_STARTED,
        events.category.GET_STARTED,
        events.action.CLICK,
        events.label.GET_STARTED,
        {
          form_location: this.formLocation,
          selected_products: this.selectedProducts.join(", "),
        }
      );
    },
    sendEmail(endPoint, postData) {
      var apiUrl = `${process.env.VUE_APP_API_BASE_URL}/${endPoint}`;
      fetch(apiUrl, {
        method: "POST",
        mode: "cors",
        body: JSON.stringify(postData),
        headers: {
          "Content-Type": "application/json",
          "Strict-Transport-Security": "max-age=31536000; includeSubDomains",
        },
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.mcia-form-input-field {
  margin: $mds-space-half-x 0;
  ::v-deep {
    .mds-label .mds-label__text {
      margin-bottom: $mds-space-1-x;
    }
  }
}

.mcia-form-input-radio,
.mcia-form-input-checkbox {
  margin: $mds-space-half-x 0;
  &-btn {
    padding-right: $mds-space-2-x;
    padding-top: $mds-space-three-quarter-x;
  }
  ::v-deep {
    .mds-fieldset__legend {
      margin-bottom: $mds-space-1-x;
    }
  }
}

.mds-layout-grid .mds-layout-grid__col{
  padding: 0 $mds-space-2-and-a-half-x;
}
::v-deep .mds-combo-box__result-list {
  max-height: 240px;
}
.mcia-form {
  &-title {
    @include mds-level-3-heading;
    margin: $mds-space-1-x 0;
  }
  &-action-btn {
    padding: $mds-space-1-x;
  }
}

.mds-modal-body {
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
